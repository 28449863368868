<template>
  <v-card elevation="0" rounded="0">
  <div class="calendar-container">
    <div class="header">
      <span class="date">{{ currentDay.locale('es').format('DD MMM YYYY') }}</span>
      <v-btn  @click="$router.push('/actividad')" class="calendar-text" elevation="0" small style="text-transform:capitalize;">
      <v-icon left>
        mdi-history
      </v-icon>
      Actividad</v-btn>
    </div>
    
    <v-row dense class="days-row mt-6">
      <v-col
        v-for="(day, index) in days"
        :key="index"
        cols="auto"
        class="day-col"
      >
        <div
          :class="['day-wrapper', { selected: day.selected, disabled: day.disabled }]"
        >
          <div :class="['circle', { selected: day.selected }]" style="text-transform:uppercase; position:relative;" @click="updateValue(day)" v-ripple>
            <span>{{ day.day }}</span>
            <span
              v-if="day.completed"
              class="check-icon"
              style="position:absolute; top:-10px;"
            >
              <v-icon color="success" x-small>mdi-check</v-icon>
            </span>
            <span
              v-else-if="day.isToday"
              class="dot primary"
              style="position:absolute; top:-5px;"
            ></span>
            <span
              v-else-if="day.scheduled"
              class="dot scheduled"
              style="position:absolute; top:-5px;"
            ></span>
          </div>
          <div class="day-number">{{ day.date }}</div>
        </div>
      </v-col>
    </v-row>
  </div>

      <v-progress-linear absolute indeterminate v-if="loading" color="primary" height="2"></v-progress-linear>

  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      days: [],
      currentDay: moment().locale('es'),
      dates: null,
      today: moment().format("YYYY-MM-DD"),
      model: null,
    };
  },

  created() {
    this.buildDaysArray();

    let dates = [];
    for (let i = 2; i > 0; i--) {
      dates.push(moment().subtract(i, "days").format("YYYY-MM-DD"));
    }
    dates.push(moment().format("YYYY-MM-DD"));
    for (let i = 1; i < this.maxDays; i++) {
      dates.push(moment().add(i, "days").format("YYYY-MM-DD"));
    }
    this.dates = dates;
  },

  mounted() {
    this.scrollToActiveDay();
  },

  methods: {
    buildDaysArray() {
      const today = moment().locale('es');
      const days = [];

      for (let i = 5; i > 0; i--) {
        let dayMoment = today.clone().subtract(i, "days");
        days.push({
          day: dayMoment.format("dd")[0],
          date: dayMoment.date(),
          moment: dayMoment,
          completed: this.isWorkoutDay(dayMoment),
          scheduled: this.isScheduledDay(dayMoment),
          selected: false,
          disabled: false,
        });
      }

      days.push({
        day: today.format("dd")[0],
        date: today.date(),
        moment: today,
        completed: this.isWorkoutDay(today),
        scheduled: this.isScheduledDay(today),
        selected: true,
        disabled: false,
        isToday: true,
      });
/* 
      let futureDay = today.clone().add(1, "days");
      days.push({
        day: futureDay.format("dd")[0],
        date: futureDay.date(),
        moment: futureDay,
        completed: this.isWorkoutDay(futureDay),
        scheduled: this.isScheduledDay(futureDay),
        selected: false,
        disabled: false,
      }); */

      this.days = days;
    },

    isWorkoutDay(dayMoment) {
      let dayStr = dayMoment;

      if (this.workouts.length > 0) {
        return this.workouts.some((workout) => {
          return moment(new Date(workout.date.seconds * 1000)).isSame(dayStr, 'day');
        });
      } else {
        return false;
      }
    },

    isScheduledDay(dayMoment) {
      return this.schedules.some((schedule) => {
        return schedule.isSame(dayMoment, 'day');
      });
    },

    formatDate(date, format) {
      let now = moment();
      if (moment(date).isSame(now, 'day') && format === 'ddd') {
        return "Hoy";
      }
      return moment(date).locale("es").format(format);
    },
    updateValue(date) {
      if (this.loading) return;
      if (this.value === date) return;

      this.$emit('input', date.moment.format("YYYY-MM-DD"));

      this.currentDay = date.moment;

      this.days.forEach((day) => {
        day.selected = false;
      });

      date.selected = true;
    },
    scrollToActiveDay() {
      let index = this.dates.indexOf(this.value);
      if (index === -1) return;

      this.model = index;
    },

    checkCompletedDays() {
      this.days.forEach((day) => {
        day.completed = this.isWorkoutDay(day.moment);
        day.scheduled = this.isScheduledDay(day.moment);
      });
    },
  },

  props: {
    workouts: {
      type: Array
    },
    value: String,
    maxDays: {
      type: Number,
      default: 3,
    },
    loading: Boolean,
    unableInteraction: Boolean,
    disabledWeekDays: {
      type: Array,
      default: () => [],
    },

    schedules: {
      type: Array
    },
  },

  watch: {
    workouts: {
      handler: function () {
        this.checkCompletedDays();
      },
    },
    schedules: {
      handler: function () {
        this.checkCompletedDays();
      },
    },
  }
};
</script>
<style scoped>
.calendar-container {
  padding: 16px;
  font-family: "Roboto", sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.date {
  font-size: 18px;
  font-weight: bold;
}

.calendar-text {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.days-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.day-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.day-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 10px;
  cursor: pointer;
}

.circle:hover {
  cursor: pointer;
}

.circle.selected {
  background-color: #2196f3;
}

.circle.disabled {
  background-color: #444444;
}

.circle .check-icon {
  position: absolute;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
}

.dot.scheduled {
  background-color: yellow;
}

.day-number {
  margin-top: 4px;
  font-size: 14px;
}

.day-wrapper.selected .day-number {
  color: inherit;
}

.day-wrapper.disabled .day-number {
  color: #888888;
}


.theme--dark .date,
.theme--dark .calendar-text,
.theme--dark .circle,
.theme--dark .day-number {
  color: #ffffff;
}

.theme--dark .circle {
  background-color: #222222;
}



.theme--light .date,
.theme--light .calendar-text,
.theme--light .circle,
.theme--light .day-number {
  color: #000000;
}

.theme--light .circle {
  background-color: #f0f0f0;
}

.circle.selected{
  outline:1px solid var(--v-secondary-base);
}
</style>

<style>
.v-badge--dot .v-badge__badge {
  inset: calc(95% - 12px) auto auto calc(50% - 2px) !important;
}
</style>
